import { css } from "@emotion/react"
import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Marquee from "react-fast-marquee"
import LazyLoad from "react-lazyload"
import Fade from "react-reveal/Fade"
import CarouselComponent from "../components/common/carousel-component"
import CTA from "../components/common/CTA"
import Frame from "../components/common/frame"
import SVGIcon from "../components/common/SVGIcon"
import TimelineCarousel from "../components/common/Timeline/TimelineCarousel"
import Navigation from "../components/navigation"
import "../styles/pages/about-us.scss"
const AboutUsNew = () => {
  const data = AboutUsNewPageQuery()
  const {
    newField,
    seo,
    pageImages,
    ctaBoxes,
    heroSection,
    linkTitle,
    content,
  } = data
  const [wireVisible, setWireVisible] = useState(false)
  const responsive = {
    allScreen: {
      breakpoint: { max: 4000, min: 0 },
      items: 1.5,
      slidesToSlide: 1,
    },
  }

  useEffect(() => {
    var container = document.querySelector(".groupAboutImg")
    var leftImgGrp = document.querySelectorAll(".left-img-grp")
    var rightImgGrp = document.querySelectorAll(".right-img-grp")

    window.addEventListener("scroll", function() {
      var scrollTop = window.pageYOffset || window.scrollY
      const rect = container?.getBoundingClientRect()
      const rightRect = rightImgGrp[0]?.getBoundingClientRect()
      if (
        !rightImgGrp.length <= 0 &&
        !leftImgGrp.length <= 0 &&
        rect?.top < 900 &&
        rect?.bottom > 1175
      ) {
        leftImgGrp[0].style.top = scrollTop - 270 + "px"
        // rightImgGrp[0].style.top = scrollTop - 145 + "px"
      }
      if (
        !rightImgGrp.length <= 0 &&
        !leftImgGrp.length <= 0 &&
        rect?.top < 900 &&
        rect?.bottom > 1302
      ) {
        rightImgGrp[0].style.top = scrollTop - 145 + "px"
      }
    })
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", handleVisibility)
    return function cleanup() {
      window.removeEventListener("scroll", handleVisibility)
    }
  }, [])

  const handleVisibility = () => {
    const springWireTop = document
      .getElementsByClassName("spring-wire-wrapper")[0]
      .getBoundingClientRect().top
    if (springWireTop < 800) setWireVisible(true)
  }

  return (
    <div className="about-page-2023">
      <Frame seo={seo}>
        <header>
          <Navigation page="Home" newButton />
        </header>

        <section className="hero-section mt60 prelative">
          <Container className="Layout-container">
            <Col lg="7" className="mx-auto text-center mb64">
              <p className="fw-600 super-pink about-head down-to-up-1">
                ABOUT US
              </p>
              <h1 className="about-title mx-auto fw-800 down-to-up-2">
                {heroSection.tag}
                <p className="title-highlight down-to-up-2">
                  {heroSection.tagLink}
                </p>
              </h1>

              <p className="about-desc mx-auto down-to-up-3">
                {heroSection.heading?.text}
              </p>
            </Col>
            <div className={`prelative  groupAboutImg mx-auto down-to-up-4`}>
              <Col
                lg={12}
                className="d-flex flex-wrap justify-content-center g-20 d-dsk mx-auto prelative"
              >
                <div className="d-flex flex-column left-img-grp position-absolute d-dsk">
                  {pageImages?.slice(0, 2).map((img, i) => (
                    <div className={`w-100`}>
                      <img src={img?.url} />
                    </div>
                  ))}
                </div>
                {pageImages?.slice(4, 8).map((img, i) => (
                  <div>
                    <img src={img?.url} className="w-100" />
                  </div>
                ))}
                <div className="d-flex flex-column right-img-grp position-absolute d-dsk">
                  {pageImages?.slice(2, 4).map((img, i) => (
                    <div className={`w-100`}>
                      <img src={img?.url} />
                    </div>
                  ))}
                </div>
              </Col>
            </div>
          </Container>

          <div className="d-mob">
            <img src={pageImages[13].url} className="w-100" />
          </div>
          <div className="d-dsk spring-wire-wrapper">
            {wireVisible && (
              <SVGIcon
                name="block/pinkSpringWire"
                className="spring-wire position-absolute w-100"
              />
            )}
          </div>
        </section>

        <section className="mt100 mb100 detail-section">
          <Container className="Layout-container">
            <Row className="d-flex justify-content-center">
              <Col lg={4}>
                <Fade bottom duration={1000}>
                  <h2 className="font-roboto fw-800 mb30">
                    {heroSection.content?.text}
                  </h2>
                </Fade>
              </Col>
              <Col lg={6} className="p16 pale-purple">
                <Fade bottom duration={1000}>
                  <p>{heroSection.heroExcerptList[0]}</p>
                  <p>{heroSection.heroExcerptList[1]}</p>
                  <p>{heroSection.heroExcerptList[2]}</p>
                </Fade>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="mission-section mb100">
          <Container className="Layout-container">
            <Row className="d-flex justify-content-center">
              <Col lg={4}>
                <Fade bottom duration={500}>
                  <h2 className="fw-800 font-roboto">{linkTitle[0]}</h2>
                  <div className="prelative z-1 d-dsk">
                    <img src={pageImages[14].url} />
                  </div>
                </Fade>
              </Col>
              <Col lg={6}>
                <div className="right-content-box br10 mt24 prelative">
                  <Fade bottom duration={500} delay={60}>
                    <p className="fw-bolder mb0">
                      {heroSection.formCtaText.text}
                    </p>
                  </Fade>

                  <div className="d-flex founder-wrapper mt50">
                    <Fade bottom duration={500} delay={80}>
                      <div>
                        <p className="fw-bolder mb8">
                          {heroSection.primaryButtonText}
                        </p>
                        <p className="role mb0">
                          {heroSection.primaryButtonLink}
                        </p>
                      </div>
                    </Fade>
                    <Fade bottom duration={500} delay={100}>
                      <div>
                        <p className="fw-bolder mb8">
                          {heroSection.secondaryButtonText}
                        </p>
                        <p className="role mb0">
                          {heroSection.secondaryButtonLink}
                        </p>
                      </div>
                    </Fade>
                  </div>
                </div>
                <div className="d-mob">
                  <img src={pageImages[15].url} className="w-100" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="who-section">
          <Container
            className="Layout-container br10"
            style={{
              background:
                "linear-gradient(180deg,rgba(252, 223, 255, 0.8) -22.67%, rgba(224, 240, 255, 0) 102.64%)",
            }}
          >
            <Col
              lg={10}
              className="who-content-wrapper text-center br10 mx-auto"
            >
              <Fade bottom duration={500} delay={80}>
                <h2 className="mb38 font-roboto fw-800">
                  {heroSection.animAltText[0]}
                </h2>
              </Fade>
              <Fade bottom duration={500} delay={80}>
                <div className="p18 mb38">
                  <p className="pale-purple">{heroSection.animAltText[1]}</p>
                </div>
              </Fade>
              <Col lg={9} className="mx-auto">
                <div className="p24">
                  <Fade bottom duration={500} delay={80}>
                    <p className="font-roboto fw-600 mb0">
                      {heroSection.animAltText[2]}
                    </p>
                  </Fade>
                </div>
              </Col>
            </Col>
          </Container>
        </section>

        <section className="mb100">
          <Container className="Layout-container">
            <div className="detail-cards-wrap d-flex justify-content-between flex-wrap">
              {newField[3].heading.map((title, i) => (
                <Fade bottom duration={500} delay={i * 20}>
                  <div
                    className="detail-single br10 prelative text-center"
                    css={css`
                      background: ${newField[3].text[i]};
                    `}
                  >
                    <span className="position-absolute fw-800">{title}</span>
                    <p className="fw-bolder">{newField[3]?.title[i]}</p>
                  </div>
                </Fade>
              ))}
            </div>
          </Container>
        </section>

        <section className="investors-section  mb100">
          <Container className="Layout-container">
            <Row className="justify-content-around">
              <Col lg={3} className="d-flex align-items-center">
                <Fade bottom duration={500}>
                  <h2
                    className="fw-800 font-roboto mb38"
                    style={{ maxWidth: "12ch" }}
                  >
                    {linkTitle[1]}
                  </h2>
                </Fade>
              </Col>
              <Col
                lg={6}
                className="d-flex flex-wrap g24 investors-right-column"
              >
                {pageImages?.slice(8, 13).map((img, i) => (
                  <div className="investors d-flex justify-content-center align-items-center">
                    <Fade bottom duration={500} delay={i * 30}>
                      <div>
                        <img loading="lazy" src={img.url} className="w-100" />
                      </div>
                    </Fade>
                  </div>
                ))}
              </Col>
            </Row>
          </Container>
        </section>

        <section className="double-marquee mb100">
          <h2 className="font-roboto text-center fw-800 mb100">
            {linkTitle[2]}
          </h2>
          <Marquee pauseOnClick pauseOnHover gradient={false}>
            {newField[1].text.map((a, i) => {
              return (
                <div className="value-card">
                  <div className="content">
                    <LazyLoad once offset={100}>
                      <img
                        loading="lazy"
                        className="feature-icon"
                        src={newField[1].image[i].url}
                      />
                    </LazyLoad>
                    <div className="feature-name alt h6 mb16">
                      <h6 className="font-roboto fw-600">{a}</h6>
                    </div>
                    <p className="feature-description m-0">
                      {newField[1].subtext[i]}
                    </p>
                  </div>
                </div>
              )
            })}
          </Marquee>
          <Marquee pauseOnClick pauseOnHover gradient={false} direction="right">
            {newField[2].text.map((a, i) => {
              return (
                <div className="value-card">
                  <div className="content">
                    <LazyLoad once offset={100}>
                      <img
                        loading="lazy"
                        className="feature-icon"
                        src={newField[2].image[i].url}
                      />
                    </LazyLoad>
                    <div className="feature-name alt h6 mb16">
                      <h6 className="font-roboto fw-600">{a}</h6>
                    </div>
                    <p className="feature-description m-0">
                      {newField[2].subtext[i]}
                    </p>
                  </div>
                </div>
              )
            })}
          </Marquee>
        </section>

        <section>
          <Fade duration={1000} delay={600}>
            <Container className="Layout-container">
              <h2 className="font-roboto fw-800">{linkTitle[3]}</h2>
              <div className="p18">
                <p>{linkTitle[4]}</p>
              </div>
              <TimelineCarousel timelineData={newField[0]} />
            </Container>
          </Fade>
        </section>

        <section className="badges-2023">
          <Container className="Layout-container">
            <div className="altv3">
              <Fade bottom duration="800" distance={"20px"}>
                <h3 className="font-roboto fw-bold heading mx-auto text-center mb48">
                  {content.text}
                </h3>
              </Fade>
              <Row className="justify-content-center">
                <Col lg={8}>
                  <div className="d-flex flex-wrap justify-content-evenly gap-40 d-dsk">
                    {newField[4].image.map((img, ind) => {
                      return (
                        <Fade
                          bottom
                          delay={(ind + 1) * 60}
                          duration="800"
                          distance={"20px"}
                        >
                          <div className="img-wrapper">
                            <img src={img.url} alt="badges" className="w-100" />
                          </div>
                        </Fade>
                      )
                    })}
                  </div>
                </Col>
              </Row>

              <div className="d-mob">
                <CarouselComponent
                  responsive={responsive}
                  partialVisbile
                  swipeable
                  customButtonNew
                  arrowClassName="btn-secondary-new"
                >
                  {newField[4].image.map((img, ind) => {
                    return (
                      <div className="badge-caro">
                        <img src={img.url} alt="badges" className="w-100" />
                      </div>
                    )
                  })}
                </CarouselComponent>
              </div>
            </div>
          </Container>
        </section>
        <section className="cta green-cta">
          <LazyLoad>
            <CTA
              data={[ctaBoxes[0]]}
              bgPattern
              className="Layout-full"
              maxDescriptionWidth="363px"
              maxBoxWidth="100%"
              maxRowWidth="1176px"
              lgLeft={5}
              lgRight={7}
              newButton
              newDesign
            />
          </LazyLoad>
        </section>
      </Frame>
    </div>
  )
}

export default AboutUsNew

export function AboutUsNewPageQuery() {
  const returneddata = useStaticQuery(graphql`
    query aboutusnew {
      SuperOps {
        pages(where: { title: "AboutUsNew" }) {
          title
          seo {
            title
            description
            keywords
            image {
              url
            }
          }
          content {
            text
          }
          linkTitle
          heroSection {
            tag
            tagLink
            heading {
              text
            }
            content {
              text
            }
            heroExcerptList
            formCtaText {
              text
            }
            primaryButtonText
            primaryButtonLink
            secondaryButtonText
            secondaryButtonLink
            animAltText
          }
          pageImages {
            url
          }
          ctaBoxes {
            type
            theme
            customBackgroundColor
            heading {
              html
            }
            description {
              html
            }
            primaryButtonText
            primaryButtonLink
            secondaryButtonText
            secondaryButtonLink
            card {
              cardName
              title
              subtext
              slug
            }
          }
          newField {
            ... on SuperOps_FaqComponent {
              faqs {
                question
                page
                linkText
                description
              }
            }
            ... on SuperOps_Card {
              heading
              title
              text
              subtext
              image {
                url
              }
            }
          }
        }
      }
    }
  `)

  return returneddata.SuperOps.pages[0]
}
